.static-bold {
    font-family: $font-static-bold;
}

.intrometro {
    font-family: $font-intrometro;
}

.btn {
    color: $color-primary;
    font-family: $font-intrometro;
    font-size: 0.77rem;
    font-weight: 700;
    padding: 0.5rem 0;
    outline: none;

    background-color: transparent;
    border: none;
    transition: $default-transition;

    .svg-inline--fa {
        transition: $default-transition;
    }

    .svg-inline--fa:not(.fa-youtube):not(.fa-camera) {
        color: $color-white;
        background: $color-primary;
        font-size: 1rem;
        padding: 2px;
        margin-right: 5px;
        vertical-align: bottom;
    }
    .fa-youtube {
        font-size: 1rem;
        margin-right: 5px;
        vertical-align: bottom;
    }

    &.color-white {
        .svg-inline--fa:not(.fa-youtube):not(.fa-camera) {
            color: $color-primary;
            background: $color-white;
        }
    }

    &:hover, &:focus, &:active {
        outline: none;
        box-shadow: none;
        color: $color-primary;
    }

    &:hover {
        .fa-arrows-alt {
            transform: rotate(90deg);
        }
        .fa-search,
        .fa-youtube,
        .fa-camera,
        .fa-download,
        .fa-link,
        .fa-twitter,
        .fa-newspaper {
            transform: scale(1.3, 1.3);
        }
    }
}

ul.inline {
    list-style-type: none;
    padding-left: 0;
    margin-bottom: 0;

    li {
        padding-left: 12px;
        position: relative;

        &::before {
            content: '-';
            position: absolute;
            left: 0px;
            top: -2px;
        }
    }
}

.img-inline {
    &.float-left {
        margin-right: 0.5rem;
        padding-top: 0.2rem;
        padding-bottom: 0.2rem;

        @media (max-width: $lg-down) {
            margin-right: 0;
            padding-top: 0.5rem;
            padding-bottom: 0.5rem;
            float: none!important;
            display: block;
        }
    }
    &.float-right {
        margin-left: 0.5rem;
        padding-top: 0.2rem;
        padding-bottom: 0.2rem;

        @media (max-width: $lg-down) {
            margin-left: 0;
            padding-top: 0.5rem;
            padding-bottom: 0.5rem;
            float: none!important;
            display: block;
        }
    }
}

.border-right {
    position: relative;
    border-right: 0 !important;

    &::after {
        content: ' ';
        display: block;
        height: 70%;
        width: 1px;
        position: absolute;
        right: 0;
        background: $color-dark;
    }
}

.bottom-zigzag {
    @include zigzag;
}
.right-zigzag {
    @include zigzag('vertical', 'right');

    @media (max-width: $lg-down) {
        background: none;
    }
}
.left-zigzag {
    @include zigzag('vertical', 'left');

    @media (max-width: $lg-down) {
        background: none;
    }
}

.banner {
    @extend .pb-5;
    margin-top: 10rem;
    padding-top: 7rem;
    position: relative;

    @media (max-width: $lg-down) {
        margin-left: -15px;
        margin-right: -15px;
    }

    .title {
        background: transparentize($color-white, 0.1);
        position: relative;
        padding-top: 2.5rem;
        padding-left: 2.5rem;
        @extend .pr-2;
        @extend .pb-2;
        @extend .ml-5;
        @extend .mt-4;

        p {
            margin: 0;
        }

        .bordered {
            position: absolute;
            z-index: 50;
            width: 103%;
            height: 105%;
            display: block;
            border: 15px solid $color-secondary;
            top: 15px;
            left: 15px;
        }

        @media (max-width: $lg-down) {
            margin-left: 0px !important;
            padding: 20px !important;
            width: auto !important;

            .bordered {
                width: 100%;
                height: 100%;
                top: 0px;
                left: 0px;
            }
        }
    }
}

.collapse {
    &.show {
        margin-top: 0.5rem;
    }
    .close {
        font-size: 3rem;
        outline: none;
        opacity: 1;
    }
}

.lead {
    font-size: 1.2rem;
    @extend .color-primary;
    @extend .text-uppercase;
    @extend .static-bold;
    @extend .mt-2;

    &.dark {
        @extend .color-dark;
        text-transform: none !important;
    }

    &.normal {
        text-transform: none !important;
    }

    &.up {
        font-size: 1.4rem;
    }
}

.wrapper-big {
    @extend .my-2;

    .big-text {
        @extend .static-bold;
        font-size: 2rem;
        line-height: 1.8rem;
    }
}
