@keyframes spin {
    100% {
        transform: rotate(360deg);
    }
}

@keyframes rotatelight {
    50% {
        transform: rotate(-4deg);
    }
    100% {
        transform: rotate(2deg);
    }
}

@keyframes movevertical {
    50% {
        top: -20px
    }
    100% {
        top: 0px;
    }
}

@keyframes moveverticalgrue {
    50% {
        top: 25px
    }
    100% {
        top: 45px;
    }
}

@keyframes highlightblink {
    20% {
        opacity: 0;
    }
    21% {
        opacity: 1;
    }
    100% {
        opacity: 1;
    }
}

@keyframes spinheartleft {
    50% {
        transform: rotate(-5deg);
    }
    100% {
        transform: rotate(3deg);
    }
}

@keyframes spinheartright {
    50% {
        transform: rotate(5deg);
    }
    100% {
        transform: rotate(-3deg);
    }
}

@keyframes opacity {
    50% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes scale {
    80% {
        transform: scale(1, 1);
    }
    100% {
        transform: scale(1, 1);
    }
}

@keyframes opacitystars {
    70% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes fusee {
    10% {
        opacity: 1;
    }

    30% {
        top: 90px;
        left: 260px;
    }

    100% {
        top: 90px;
        left: 260px;
        opacity: 1;
    }
}
