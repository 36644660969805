#header {
    @media (max-width: $lg-down) {
        .order-lg-2 {
            &::after {
                display: none;
            }
        }

        .order-lg-1, .order-lg-3 {
            padding-top: 2rem;
            img {
                max-height: 80px;
            }
        }
    }

    .logo {
        overflow: hidden;
        .rapport, .deuxsept, .annuel {
            position: absolute;
            top: 0;
            left: 0;
        }
        .rapport {
            top: -100%;
            animation: rapport 3s ease 2s forwards 1;
        }
        .deuxsept {
            top: 100%;
            animation: deuxsept 3s ease 2s forwards 1;
        }
        .annuel {
            opacity: 0;
            animation: annuel 3s ease 3s forwards 1;
        }
    }
}

nav {
    color: $color-dark;
    font-family: $font-static-bold;
    font-size: 1.278rem;

    button.navbar-toggler {
        outline: 0;
        &:focus {
            outline: 0;
        }
    }

    .nav {
        position: relative;

        @media (max-width: $lg-down) {
            &:not(.show) {
                display: none;
            }
        }

        .nav-item {
            position: relative;

            &::before {
                content: ' ';
                position: absolute;
                left: 0;
                top: 30%;
                height: 35%;
                width: 1px;
                background: $color-white;
                @media (max-width: $lg-down) {
                    display: none;
                }
            }

            .nav-link {
                color: $text-color;
                padding: .5rem 1.5rem;

                .svg-inline--fa {
                    position: absolute;
                    right: 15px;
                    bottom: -15px;
                    @media (max-width: $lg-down) {
                        display: none;
                    }
                }

                &.img {
                    position: relative;

                    &.cent {
                        width: 100px;
                    }

                    &.twitt {
                        width: 100px;
                    }

                    img {
                        position: absolute;
                        top: -9px;
                        left: 16px;

                        @media (max-width: $lg-down) {
                            position: relative;
                            top: 0px;
                            margin-top: 36px;
                        }
                    }
                }

                &:hover, &.active {
                    color: $color-white;
                    background-color: transparent;
                }

                &::after {
                    display: none;
                }
            }

            .submenu {
                position: absolute;
                background: $color-secondary;
                width: 230px;
                top: 90%;
                display: none;
                border: 0;
                border-radius: 0;
                margin: 0;
                padding: 0;
                padding-bottom: 0.5rem;
                font-size: 1.278rem;
                color: $color-dark;

                @media (max-width: $lg-down) {
                    display: block;
                    position: relative;
                    top: 0;
                }

                .nav-link {
                    padding: 0.2rem 1.5rem;
                    padding-left: 2.5rem;
                    line-height: 1;
                    position: relative;

                    &::before {
                        content: ' ';
                        height: 2px;
                        width: 0.7rem;
                        top: 50%;
                        left: 1.5rem;
                        position: absolute;
                        background: $color-white;
                        border-radius: 5px;
                    }
                }
            }

            &:hover {
                .submenu {
                    display: block;
                }
            }
        }

        &::after {
            content: ' ';
            position: absolute;
            right: 0;
            top: 30%;
            height: 35%;
            width: 1px;
            background: $color-white;
            @media (max-width: $lg-down) {
                display: none;
            }
        }
    }
}
@keyframes annuel {
    50% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
@keyframes rapport {
    100% {
        top: 0;
    }
}
@keyframes deuxsept {
    100% {
        top: 0;
    }
}
