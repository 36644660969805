@font-face {
    font-family: 'intrometroregular';
    src: url('fonts/intrometro-webfont.woff2') format('woff2'),
        url('fonts/intrometro-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'static_boldbold';
    src: url('fonts/static_bold-webfont.woff2') format('woff2'),
        url('fonts/static_bold-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'staticregular';
    src: url('fonts/static-webfont.woff2') format('woff2'),
        url('fonts/static-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}
