#logement-demain {
    .text-neg {
        position: relative;
        margin-top: -120px;
        width: 55%;

        @media (max-width: $lg-down) {
            margin-top: 0px;
            width: auto;
        }
    }

    #construire {
        .banner {
            background: url('#{$path-img}/logement-demain/construire/construire.jpg') no-repeat center center;
            background-size: cover;

            .title {
                width: 65%;
            }
        }

        .culture-interne {
            position: relative;
            .tasse {
                position: absolute;
                top: -45px;
                right: 90px;
            }
        }

        .coloration-emh {
            position: relative;

            > div {
                position: relative;
                z-index: 5;
            }

            &::after, &::before {
                position: absolute;
                content: ' ';
                display: block;
                top: 0;
                left: 0;
                height: 100%;
                width: 100%;
            }

            &::after {
                z-index: 3;
                background: $color-primary;
                transform: rotate(-1deg);
            }

            &::before {
                z-index: 2;
                background: $color-secondary;
                transform: rotate(2deg);
                animation: rotatelight 5s ease infinite;
            }
        }

        .operation {
            position: relative;
            .loupe {
                position: absolute;
                top: 5px;
                left: -63px;
            }
            h3 {
                span {
                    padding-left: 2rem;
                }
            }
        }

        .ville-demain {
            border: 15px solid $color-secondary;
        }
    }

    #rehabiliter {
        .banner {
            background: url('#{$path-img}/logement-demain/rehabiliter/rehabiliter.jpg') no-repeat center center;
            background-size: cover;
            .title {
                width: 60%;
            }
        }

        .locataire {

            > div {
                position: relative;

                h3 {
                    font-size: 3rem;
                }

                &::before {
                    position: absolute;
                    z-index: -1;
                    content: ' ';
                    display: block;
                    top: 0;
                    left: 0;
                    height: 100%;
                    width: 100%;
                    background: $color-primary;
                    transform: rotate(1deg);
                }

                .show-photo {
                    .photo {
                        display: none;
                        position: absolute;
                        z-index: 1;
                        top: -1%;
                        left: 95%;
                        height: 105%;
                        width: 210%;
                        background: $color-primary;
                        transform: rotate(1deg);

                        .icon {
                            position: absolute;
                            right: 30px;
                            top: 20px;
                            z-index: 1;
                            cursor: pointer;
                            .fa-times {
                                position: absolute;
                                top: 42px;
                                right: 38px;
                            }
                        }

                        img {
                            transform: rotate(-1deg);
                        }
                    }

                    .btn {
                        .svg-inline--fa {
                            vertical-align: -0.3em;
                            @extend .pr-2;
                        }
                    }
                }
            }
        }

        .emh-env {
            border: 15px solid $color-primary;
        }
    }
}

.wrapper-building {
    .grue {
        .poids {
            position: absolute;
            top: 45px;
            right: 12px;
            animation: moveverticalgrue 5s ease infinite;
        }
    }

    .highlight {
        position: absolute;
        width: 3px;
        height: 6px;
        background-color: #FFFF00;
        box-shadow: 0px 0px 80px 0px rgba(255, 255, 0, 0.8);
        opacity: 0;
    }

    .building-3 {
        .highlight {
            top: 37px;
            right: 20px;
            animation: highlightblink 10s linear infinite;

            &.lamp {
                width: 5px;
                height: 5px;
                border-radius: 50%;
                box-shadow: 0px 15px 54px 8px rgba(255, 255, 0, 1);
                left: 24px;
                top: 46px;
                animation: highlightblink 17s linear infinite;
            }
        }
    }

    .building-4 {
        .highlight {
            &.one {
                top: 27px;
                left: 25px;
                animation: highlightblink 7s linear infinite;
            }

            &.two {
                top: 56px;
                right: 26px;
                animation: highlightblink 15s linear infinite;
            }
        }
    }
}
