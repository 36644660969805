/**** BREAKPOINT ****/
$sm-up      : 576px;
$md-up      : 768px;
$lg-up      : 992px;
$xl-up      : 1200px;
$xxl-up     : 1600px;
$sm-down    : 575.98px;
$md-down    : 767.98px;
$lg-down    : 991.98px;
$xl-down    : 1199.98px;
$xxl-down   : 1599.98px;

/**** COLORS GENERAL ****/
$color-primary        : #ca138c;
$color-secondary      : #fcb94c;
$color-dark           : #231f20;
$color-white          : #ffffff;

$text-color           : $color-dark;

$colors: ("primary", $color-primary),
    ("secondary", $color-secondary),
    ("white", $color-white),
    ("dark", $color-dark);

@each $colored in $colors {
    .bg-#{nth($colored, 1)} {
        background-color: #{nth($colored, 2)} !important;
    }
    .color-#{nth($colored, 1)} {
        color: #{nth($colored, 2)} !important;
    }
}

/**** FONTS *****/
$font-family: 'staticregular', Arial, sans-serif;
$font-static-bold: 'static_boldbold', Arial, sans-serif;
$font-intrometro: 'intrometroregular', Arial, sans-serif;

/**** PATH IMAGES *****/
$path-img: '../images/';

/**** DEFAULT TRANSITION *****/
$default-transition : all 0.2s ease;

/**** OTHER *****/
$button-radius: 24px;
