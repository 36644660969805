#avant-propos {
    .text-right {
        >p, >h2 {
            &::after {
                @extend .my-3;
                content: ' ';
                position: relative;
                display: block;
                width: 15px;
                height: 1px;
                background: $color-dark;
                left: 93%;
            }
        }

        >p {
            font-size: 0.944rem;

            span {
                font-size: 1.3rem;
            }
        }
    }
}

.nav.credits {
    li {
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        border-right: 1px solid $color-dark;
        &:first-child {
            border-left: 1px solid $color-dark;
            @media (max-width: $lg-down) {
                border: 0;
                margin-bottom: 1rem;
                a {
                    flex: 0 0 auto;
                }
            }
        }
        &:last-child {
            @media (max-width: $lg-down) {
                border-right: 0;
            }
        }
        a {
            color: $color-dark;
            outline: 0;
        }
        a.rs {
            @extend .ml-3;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: $color-primary;
            border-radius: 50%;
            height: 35px;
            width: 35px;
            transition: $default-transition;

            .svg-inline--fa {
                color: $color-white;
            }

            &:hover {
                background-color: $color-secondary;
            }
        }
    }
}
