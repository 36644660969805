.modal-backdrop {
    background-color: $color-white;
}

.modal {
    .modal-content {
        @extend .shadow-lg;
        border-radius: 0;
        border: 0;

        @media (max-width: $lg-down) {
            max-width: 100%;
        }

        .modal-header {
            border: 0;
            margin: 0 6rem;
            background: url('#{$path-img}/border-popup.png') repeat-x bottom left;

            @media (max-width: $lg-down) {
                margin: 0 1.5rem;
                margin-bottom: 2rem;
            }

            .close {
                font-size: 3rem;
                opacity: 1;
                padding: 0.5rem;
                position: absolute;
                right: 30px;
                outline: none;

                @media (max-width: $lg-down) {
                    font-size: 4rem;
                    top: 17px;
                }
            }
        }

        .modal-body {
            @extend .px-5;
            @extend .mx-5;

            @media (max-width: $lg-down) {
                margin: 0!important;
                padding-left: 1.5rem!important;
                padding-right: 1.5rem!important;
            }
        }

        .modal-footer {
            margin: 0 6rem;
            margin-bottom: 1rem;
            padding: 0.5rem;
            border: 0;
            background: url('#{$path-img}/border-popup.png') repeat-x bottom left;

            @media (max-width: $lg-down) {
                margin: 0 1.5rem;
            }
        }
    }

    &#modal-bret,
    &#modal-mag,
    &#modal-news,
    &#modal-digitalisation,
    &#modal-rse1 {
        .modal-header,
        .modal-footer {
            background: none;
        }
    }

    &#modal-op {
        .modal-content {
            .loupe {
                position: absolute;
                top: 40px;
                left: 10px;
                width: 75px;
            }
        }
    }

    /*&#modal-innovation {
        .modal-content {
            .modal-header {
                background: none;
            }

            .modal-body {
                position: relative;
                @extend .py-4;
                @extend .px-4;
                @extend .mb-4;

                &::before {
                    position: absolute;
                    content: ' ';
                    display: block;
                    top: 0px;
                    left: 0px;
                    height: 100%;
                    width: 100%;
                    border: 15px solid $color-secondary;
                    transform: rotate(-1deg);
                }
            }
        }
    }*/

    &.modal-step {
        @media (min-width: $lg-up) {
            .modal-lg {
                max-width: 900px;
            }
        }

        .modal-content {
            .modal-header {
                margin: 0 3rem;

                .close {
                    right: 25px;
                    top: 12px;
                }
            }

            .modal-body {
                @extend .px-0;
                @extend .mx-5;

                h3 {
                    @extend .d-flex;
                    @extend .align-items-center;
                    @extend .mb-3;

                    &::before {
                        display: inline-block;
                        content: '1';
                        width: 100px;
                        height: 100px;
                        font-family: $font-intrometro;
                        color: $color-primary;
                        border: 10px solid $color-primary;
                        border-radius: 50%;
                        flex: 0 0 auto;
                        text-align: center;
                        font-size: 4rem;
                        margin-right: 1rem;
                        @media (max-width: $lg-down) {
                            width: 80px;
                            height: 80px;
                        }
                    }

                    &.two {
                        &::before {
                            content: '2';
                        }
                    }
                    &.three {
                        &::before {
                            content: '3';
                        }
                    }
                    &.four {
                        &::before {
                            content: '4';
                        }
                    }
                    &.five {
                        &::before {
                            content: '5';
                        }
                    }
                    &.six {
                        &::before {
                            content: '6';
                        }
                    }
                }
            }

            .modal-footer {
                margin-right: 3rem;
                margin-left: 3rem;
                position: relative;

                .close {
                    right: -38px;
                    top: -21px;
                    font-size: 3rem;
                    opacity: 1;
                    padding: .5rem;
                    position: absolute;
                    outline: none;
                }
            }
        }
    }
}
