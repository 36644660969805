#relation-client {
    .text-neg {
        position: relative;
        margin-top: -110px;
        width: 55%;

        @media (max-width: $lg-down) {
            margin-top: 0px;
            width: auto;
        }
    }

    .banner {
        background: url('#{$path-img}/relation-client/relation-client.jpg') no-repeat center center;
        background-size: cover;
        min-height: 350px;

        .title {
            width: 58%;
        }

        &.rehabiliter {
            background: url('#{$path-img}/relation-client/rehabiliter.jpg') no-repeat center center;
            min-height: 450px;
        }

        &.rse {
            background: url('#{$path-img}/rse/rse.jpg') no-repeat center top;
            min-height: 450px;
        }
    }

    h3 {
        .big {
            font-size: 3.8rem;
        }
    }

    .ss-partie {
        @extend .d-flex, .justify-content-start, .mx-md-5;

        &-title {
            @extend .p-2;
            border: 10px solid $color-secondary;
            font-family: $font-intrometro;
            color: $color-secondary;
            font-size: 1.5rem;
            white-space: nowrap;
        }

        &-waves {
            @extend .py-2;
            width: 100%;

            &-wave {
                width: 100%;
                height: 10px;
                background: url('#{$path-img}/border-yellow.png') repeat-x center center;
            }
        }
    }

    .step-op {
        position: relative;
        font-weight: bold;

        &::before {
            content: '1';
            position: absolute;
            top: -6px;
            left: -40px;
            width: 30px;
            height: 30px;
            font-size: 1.5rem;
            line-height: 1.25rem;
            border: 3px solid $color-primary;
            border-radius: 50%;
            font-family: $font-intrometro;
            display: flex;
            justify-content: center;

            @media (max-width: $md-down) {
                left: -33px;
            }
        }
        &.two {
            &::before {
                content: '2';
            }
        }
    }

    .relationnelle {
        > div {
            position: relative;

            h3 {
                font-size: 3rem;
            }

            &::before {
                position: absolute;
                z-index: -1;
                content: ' ';
                display: block;
                top: 0;
                left: 0;
                height: 100%;
                width: 100%;
                background: $color-primary;
                transform: rotate(1deg);
            }
        }
    }

    .qlq-chiffres {
        position: relative;

        >div {
            position: relative;
            z-index: 5;
        }

        &::before {
            position: absolute;
            content: ' ';
            display: block;
            top: -4px;
            left: 15px;
            height: 103%;
            width: 99%;
            z-index: -1;
            background: $color-secondary;
            transform: rotate(2deg);

            //animation: rotatelight 5s ease infinite;
            @media (max-width: $md-down) {
                display: none;
            }
        }
    }

    .innovation {
        > div {
            position: relative;

            &::before {
                position: absolute;
                content: ' ';
                display: block;
                top: -18px;
                left: -24px;
                height: 103%;
                width: 108%;
                border: 15px solid $color-secondary;
                transform: rotate(-1deg);
                transition: $default-transition;
            }

            &:hover {
                &::before {
                    transform: rotate(1deg);
                }
            }
        }
    }

    .digitaliser {
        border: 15px solid $color-secondary;
    }

    .rehabilitation {
        position: relative;
        color: $color-dark;

        h3 {
            font-size: 3rem;
        }

        >div {
            position: relative;
            z-index: 5;
        }

        &::before {
            position: absolute;
            content: ' ';
            display: block;
            top: -4px;
            left: 15px;
            height: 103%;
            width: 99%;
            z-index: -1;
            background: $color-primary;
            transform: rotate(2deg);

            //animation: rotatelight 5s ease infinite;
            @media (max-width: $md-down) {
                display: none;
            }
        }
    }
}

.border-fat {
    border: 15px solid $color-secondary;
}

.border-bottom {
    border-color: $color-dark !important;
}

.rse {
    .feuille {
        display: inline-block;
        position: relative;
        flex: 0 0 100px;

        &::before {
            position: absolute;
            z-index: 1;
            content: ' ';
            width: 50%;
            height: 50%;
            top: 25%;
            left: 25%;
            background: $color-secondary;
            animation: opacity 3s ease infinite;
        }

        img {
            position: relative;
            z-index: 1;
        }
    }
}

.magic-1 {
    position: absolute;
    bottom: -135%;
    left: 13%;
    z-index: 1;

    .stars {
        position: absolute;
        top: -35px;
        right: -50px;
        animation: opacity 2s ease infinite;
    }
}

.magic-2 {
    position: absolute;
    top: 10%;
    right: 4%;
    z-index: 1;

    .stars21 {
        position: absolute;
        top: -19px;
        left: -20px;
        transform: scale(0.4, 0.4);
        animation: scale 2s ease infinite;
    }

    .stars22 {
        position: absolute;
        opacity: 0;
        top: -37px;
        left: -40px;
        animation: opacitystars 2s ease infinite;
    }
}
