/* FONT SIZE */
@media (max-width: $xxl-down) {
    html {
        font-size: 95%;
    }
}
@media (max-width: $lg-down) {
    html {
        font-size: 90%;
    }
}
@media (max-width: $md-down) {
    html {
        font-size: 85%;
    }
}
@media (max-width: $sm-down) {
    html {
        font-size: 80%;
    }
}

/* BODY STYLE */
body {
    color: $text-color;
    font-family: $font-family;
    font-size: 18px;
    line-height: 1.3;
}

body {
    position: relative;
}

/* TITLE */
h1,
h2 {
    font-family: $font-intrometro;
}

h2 {
    font-size: 2.4rem;
}

h3, h4 {
    font-size: 2rem;
    line-height: 1;
    font-family: $font-static-bold;
    margin-bottom: 10px;
}

p {
    margin: 0;
}

a,
a:active,
a:hover,
a:visited {
    text-decoration: none;
}

section {
    position: relative;
    z-index: 10;
}

img {
    max-width: 100%;
}

small {
    line-height: 1rem;
    display: inline-block;
}

.backtotop {
    bottom: 10px;
    width: 50px;
    left: 96%;
    height: 50px;
    background-color: $color-secondary;
    color: $color-white;
    font-size: 3rem;
    transition: $default-transition;
    &:hover {
        background-color: $color-primary;
        color: $color-white;
    }
    @media (max-width: $lg-down) {
        display: none !important;
    }
}

/* stylelint-disable */
#tarteaucitronIcon {
    display: none !important;
}

.tarteaucitronSelfLink {
    display: none !important;
}
/* stylelint-enable */
