.timeline-number {
    position: absolute;
    top: -135px;
    left: 0px;
    width: 100%;

    .number {
        font-size: 2.625rem;
        font-family: $font-intrometro;
    }
    .spin1, .spin2, .elem1, .elem2, .elem3, .elem4 {
        position: absolute;
        top: 0;
        left: 0;
        max-width: unset;
        @media (max-width: 1024px) {
            display: none;
        }
    }
    .elem1, .elem2, .elem3, .elem4 {
        width: 20%;

        &.scale {
            transform: scale(0, 0);
            animation: scale 3s ease infinite;
        }
        &.spin {
            animation: spin 20s ease infinite;
        }
        &.opacity {
            animation: opacity 10s ease infinite;
        }
        &.opacity2 {
            animation: opacity 5s ease infinite;
        }
    }

    a.btn-timeline {
        outline: 0;
        position: absolute;
        bottom: 0;
        right: 0;
        cursor: pointer;
        @media (max-width: $lg-down) {
            display: none;
        }

        .svg-inline--fa {
            font-size: 2rem;
            color: $color-secondary;
            background-color: $color-white;
            border-radius: 50%;
        }

        &:hover {
            .svg-inline--fa {
                color: $color-primary;
            }
        }
    }
}

body .popover {
    border-radius: 0;
    border: 0;
    top: 170px !important;
    max-width: 200px;
    margin-left: 0px;
    height: 350px;
    background: transparent;

    .arrow {
        display: none;
    }

    .popover-body {
        background: $color-primary;
        color: $color-white;
        font-size: 1.1rem;
        line-height: 1.2rem;
        font-family: $font-family;
    }
}

#construire {
    .timeline-number {
        top: -105px;
        .round {
            position: absolute;
            text-align: center;
            @media (max-width: $lg-down) {
                p.color-white {
                    color: $color-primary !important;
                }
            }
            &.two {
                font-size: 0.9rem;
                left: 19%;
                top: 33%;
                line-height: 1rem;
                width: 96px;
                height: 68px;
                @media (max-width: 1024px) {
                    left: 18%;
                }
                @media (max-width: $lg-down) {
                    width: 135px;
                    height: 135px;
                    border: 10px solid #ca138c;
                    background: $color-white;
                    border-radius: 50%;
                    top: 0;
                    left: 5%;
                }
                @media (max-width: $md-down) {
                    top: 50px;
                    left: 5%;
                    width: 105px;
                    height: 105px;
                    background: $color-white;
                    border: 5px solid #ca138c;
                    border-radius: 50%;
                }
                .number {
                    font-size: 2.5rem;
                    @media (max-width: $md-down) {
                        font-size: 2.8rem;
                        line-height: 3rem;
                    }
                }
                .spin1 {
                    width: 168%;
                    top: -78%;
                    left: -36%;
                    animation: spin 10s linear infinite;
                }
                .spin2 {
                    width: 123%;
                    top: -47%;
                    left: -13%;
                    animation: spin 12s linear infinite;
                    animation-direction: reverse;
                }
                a.btn-timeline {
                    bottom: -25px;
                    right: -10px;
                }
                .elem1 {
                    top: -70px;
                    left: -20px;
                    width: 15%;
                }
                .elem2 {
                    top: -40px;
                    left: 120px;
                }
            }
            &.three {
                left: 44%;
                top: 7%;
                width: 135px;
                height: 135px;
                border: 10px solid $color-primary;
                border-radius: 50%;
                line-height: 0.9rem;
                font-size: 0.9rem;
                @media (max-width: 1024px) {
                    left: 43%;
                    top: 0%;
                }
                @media (max-width: $lg-down) {
                    background: $color-white;
                    top: 0;
                    left: 40%;
                }
                @media (max-width: $md-down) {
                    top: 50px;
                    left: 37%;
                    width: 105px;
                    height: 105px;
                    background: $color-white;
                    border: 5px solid #ca138c;
                    border-radius: 50%;
                }
                .number {
                    font-size: 2.5rem;
                    line-height: 1.5rem;

                    @media (max-width: $md-down) {
                        font-size: 2.5rem;
                        line-height: 1.5rem;
                    }
                }
                .spin1 {
                    width: 225px;
                    top: -47%;
                    left: -47%;
                    animation: spin 6s linear infinite;
                    animation-direction: reverse;
                }
                .spin2 {
                    width: 140%;
                    top: -20%;
                    left: -20%;
                    animation: spin 7s linear infinite;
                }
                a.btn-timeline {
                    bottom: -8px;
                    right: -10px;
                }
                .elem1 {
                    top: -60px;
                    left: -20px;
                }
            }
            &.four {
                font-size: 0.9rem;
                left: 71.3%;
                top: 24%;
                line-height: 1rem;
                width: 90px;
                height: 90px;
                @media (max-width: 1024px) {
                    left: 70.2%;
                    top: 20%;
                }
                @media (max-width: $lg-down) {
                    width: 135px;
                    height: 135px;
                    border: 10px solid #ca138c;
                    background: $color-white;
                    border-radius: 50%;
                    top: 0;
                    left: unset;
                    right: 5%;
                }
                @media (max-width: $md-down) {
                    top: 50px;
                    width: 105px;
                    height: 105px;
                    background: $color-white;
                    border: 5px solid #ca138c;
                    border-radius: 50%;
                }
                .number {
                    font-size: 2rem;
                    @media (max-width: 1024px) {
                        font-size: 1.5rem;
                    }
                    @media (max-width: $lg-down) {
                        font-size: 2rem;
                        line-height: 2rem;
                    }
                    @media (max-width: $md-down) {
                        font-size: 1.8rem;
                        line-height: 1.8rem;
                    }
                }
                a.btn-timeline {
                    bottom: -23px;
                    right: -10px;
                }
                .elem1 {
                    top: -70px;
                    left: 40px;
                }
                .elem2 {
                    top: -50px;
                    left: 90px;
                }
            }
        }
    }
}

#rehabiliter {
    .timeline-number {
        .round {
            position: absolute;
            text-align: center;
            &.one {
                font-size: 1.25rem;
                left: 7.8%;
                top: 38%;
                width: 145px;
                @media (max-width: 1024px) {
                    left: 6.5%;
                }
                @media (max-width: $lg-down) {
                    top: 50px;
                    left: 0;
                    height: 115px;
                    width: 115px;
                    border: 10px solid $color-primary;
                    background: $color-white;
                    border-radius: 50%;
                    p {
                        line-height: 1.2rem;
                    }
                }
                .number {
                    line-height: 2.3rem;
                    @media (max-width: $lg-down) {
                        font-size: 2rem;
                        line-height: 1.5rem;
                    }
                }
                @media (max-width: $md-down) {
                    top: 20px;
                    left: 0;
                    height: 85px;
                    width: 85px;
                    border: 5px solid $color-primary;
                    font-size: .9rem;

                    .number {
                        font-size: 1.5rem;
                    }
                }
            }
            &.two {
                font-size: 0.725rem;
                left: 27%;
                top: 31%;
                width: 105px;
                height: 105px;
                border: 15px solid $color-primary;
                border-radius: 50%;
                line-height: 0.7rem;
                .number {
                    font-size: 1.875rem;
                }
                @media (max-width: 1024px) {
                    left: 26.1%;
                    top: 27%;
                }
                @media (max-width: $lg-down) {
                    top: 50px;
                    left: 20%;
                    height: 115px;
                    width: 115px;
                    border: 10px solid $color-primary;
                    background: $color-white;
                    border-radius: 50%;
                    font-size: 1.2rem;
                    line-height: 1.2rem;
                    .number {
                        font-size: 2.5rem;
                    }
                }
                @media (max-width: $md-down) {
                    top: 20px;
                    left: 36%;
                    height: 85px;
                    width: 85px;
                    border: 5px solid $color-primary;
                    font-size: .9rem;

                    .number {
                        font-size: 1.5rem;
                    }
                }
                .spin1 {
                    width: 185px;
                    top: -73%;
                    left: -74%;
                    animation: spin 15s linear infinite;
                }
                .elem1 {
                    top: -65px;
                    width: 23%;
                }
                .elem2 {
                    top: -85px;
                    left: 40px;
                    width: 50%;
                }
                .elem3 {
                    top: -60px;
                    left: 90px;
                    width: 30%;
                    animation: spin 9s ease infinite;
                }
                .elem4 {
                    top: -40px;
                    left: 110px;
                    width: 45%;
                }
            }
            &.two-half {
                left: 44.5%;
                top: 44%;
                font-size: 1rem;
                line-height: 1rem;
                .number {
                    font-size: 2.8rem;
                }
                @media (max-width: 1024px) {
                    left: 44.5%;
                    top: 43%;

                    .number {
                        font-size: 2.4rem;
                    }
                }
                @media (max-width: $lg-down) {
                    top: 50px;
                    left: 41%;
                    height: 115px;
                    width: 115px;
                    border: 10px solid $color-primary;
                    background: $color-white;
                    border-radius: 50%;
                    font-size: 1.2rem;
                    line-height: 1.2rem;

                    .number {
                        font-size: 2.5rem;
                    }
                }
                @media (max-width: $md-down) {
                    top: 20px;
                    left: unset;
                    right: 0;
                    height: 85px;
                    width: 85px;
                    border: 5px solid $color-primary;
                    font-size: .9rem;

                    .number {
                        font-size: 1.5rem;
                    }
                }

                a.btn-timeline {
                    bottom: -33px;
                    right: 0px;
                }
            }
            &.three {
                left: 59.7%;
                top: 25%;
                width: 145px;
                height: 145px;
                border: 10px solid $color-primary;
                border-radius: 50%;
                line-height: 1.3rem;
                @media (max-width: 1024px) {
                    left: 58.7%;
                    top: 21%;
                }
                @media (max-width: $lg-down) {
                    top: 50px;
                    left: 63%;
                    height: 115px;
                    width: 115px;
                    border: 10px solid $color-primary;
                    background: $color-white;
                    border-radius: 50%;
                    .number {
                        font-size: 2rem;
                        line-height: 1.5rem;
                    }
                }
                @media (max-width: $md-down) {
                    top: 120px;
                    left: 18%;
                    height: 85px;
                    width: 85px;
                    border: 5px solid $color-primary;
                    font-size: .9rem;
                    line-height: 1rem;

                    .number {
                        font-size: 1.5rem;
                    }
                }
                .spin1 {
                    width: 130%;
                    top: -15%;
                    left: -15%;
                    animation: spin 9s linear infinite;
                }
                .spin2 {
                    width: 230px;
                    top: -43%;
                    left: -43%;
                    animation: spin 9s linear infinite;
                    animation-direction: reverse;
                }
                .elem1 {
                    top: -60px;
                    left: -20px;
                }
            }
            &.four {
                left: 82.7%;
                top: 23%;
                width: 145px;
                height: 145px;
                background: $color-primary;
                border-radius: 50%;
                line-height: 1.3rem;
                @media (max-width: 1024px) {
                    left: 81.5%;
                    top: 19%;
                }
                @media (max-width: $lg-down) {
                    top: 50px;
                    left: unset;
                    right: 0;
                    height: 115px;
                    width: 115px;
                    border: 10px solid $color-primary;
                    background: $color-primary;
                }
                @media (max-width: $md-down) {
                    top: 120px;
                    left: unset;
                    right: 18%;
                    height: 85px;
                    width: 85px;
                    border: 5px solid $color-primary;
                    font-size: .9rem;
                    line-height: 1rem;

                    .number {
                        font-size: 1.5rem;
                    }
                }
                a.btn-timeline {
                    bottom: -10px;
                    right: -8px;
                    z-index: 1;
                }
                .spin1 {
                    width: 129%;
                    top: -15%;
                    left: -15%;
                    animation: spin 5s linear infinite;
                    animation-direction: reverse;
                }
                .spin2 {
                    width: 104%;
                    top: -2%;
                    left: -2%;
                    animation: spin 5s linear infinite;
                }
                .elem1 {
                    top: -70px;
                    left: 40px;
                }
                .elem2 {
                    top: -50px;
                    left: 90px;
                }
                .bulle {
                    position: absolute;
                    left: -58px;
                    top: 59px;
                }
            }
        }
    }
}

#relation-client {
    /*.timeline-number {
        top: -95px;
        .round {
            position: absolute;
            text-align: center;
            &.one {
                font-size: 0.9rem;
                left: 14.3%;
                top: 25%;
                width: 103px;
                height: 90px;
                .number {
                    line-height: 2.3rem;
                    font-size: 1.91rem;
                }
                @media (max-width: 1024px) {
                    left: 13.5%;
                    top: 21%;
                    .number {
                        font-size: 1.5rem;
                    }
                }
                @media (max-width: $lg-down) {
                    left: 0;
                    top: 10px;
                    width: 115px;
                    height: 115px;
                    font-size: 1.2rem;
                    line-height: 1.2rem;
                    background: $color-primary;
                    border-radius: 50%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
                @media (max-width: $md-down) {
                    top: -50px;
                    width: 85px;
                    height: 85px;
                    font-size: 1rem;
                }
                .spin1 {
                    width: 155%;
                    top: -44%;
                    left: -27%;
                    animation: spin 20s linear infinite;
                }
                .spin2 {
                    width: 116%;
                    top: -22%;
                    left: -8%;
                    animation: spin 18s linear infinite;
                    animation-direction: reverse;
                }
                .elem1 {
                    top: -50px;
                    left: -10px;
                    width: 10%;
                }
                .elem2 {
                    top: -40px;
                    left: 120px;
                    width: 15%;
                }
            }
            &.two {
                left: 38.8%;
                top: 20%;
                width: 105px;
                height: 105px;
                line-height: .95rem;
                font-size: .95rem;
                .number {
                    font-size: 1.95rem;
                }
                @media (max-width: 1024px) {
                    left: 38%;
                    top: 16%;
                }
                @media (max-width: $lg-down) {
                    left: 27%;
                    top: 10px;
                    width: 115px;
                    height: 115px;
                    border: 10px solid $color-primary;
                    background: $color-white;
                    border-radius: 50%;
                    .number {
                        font-size: 1.6rem;
                    }
                }
                @media (max-width: $md-down) {
                    top: -50px;
                    left: 37%;
                    width: 85px;
                    height: 85px;
                    border: 5px solid $color-primary;
                    font-size: 1rem;
                    .number {
                        font-size: 1.2rem;
                    }
                }
                .spin1 {
                    width: 220px;
                    top: -60%;
                    left: -54%;
                    animation: spin 8s linear infinite;
                    animation-direction: reverse;
                }
                .spin2 {
                    width: 154%;
                    top: -32%;
                    left: -26%;
                    animation: spin 10s linear infinite;
                }
                .elem1 {
                    top: -70px;
                    left: -30px;
                }
            }
            &.three {
                left: 58.5%;
                top: 8%;
                width: 145px;
                height: 145px;
                line-height: 1.3rem;
                .number {
                    font-size: 1.6rem;
                }
                @media (max-width: 1024px) {
                    left: 57.2%;
                    top: 1%;
                }
                @media (max-width: $lg-down) {
                    left: 55%;
                    top: 10px;
                    width: 115px;
                    height: 115px;
                    border: 10px solid $color-primary;
                    background: $color-white;
                    border-radius: 50%;
                }
                @media (max-width: $md-down) {
                    top: -50px;
                    left: unset;
                    right: 0;
                    width: 85px;
                    height: 85px;
                    border: 5px solid $color-primary;
                    font-size: 1rem;

                    .number {
                        font-size: 1.2rem;
                    }
                }
                .elem1 {
                    top: -50px;
                    left: 40px;
                    width: 15%;
                }
                .elem2 {
                    top: -30px;
                    left: 110px;
                    width: 13%;
                }
            }
            &.four {
                left: 77.5%;
                top: 8%;
                line-height: 1rem;
                font-size: 0.8rem;
                .number {
                    font-size: 5rem;
                    line-height: 1.6rem;
                    display: block;
                }
                @media (max-width: $lg-down) {
                    left: unset;
                    right: 0;
                    top: 10px;
                }
                @media (max-width: $md-down) {
                    right: 30%;
                    top: 60px;
                }
                .more-number {
                    width: 125px;
                    height: 125px;
                    background: $color-primary;
                    border-radius: 50%;
                    border: 10px solid $color-secondary;
                    padding-top: 15px;
                    cursor: pointer;
                    transition: $default-transition;
                    &:hover {
                        background: $color-secondary;
                        border-color: $color-primary;
                    }
                }
            }
        }
    }*/

    .timeline-number.construire {
        top: -105px;

        .round {
            position: absolute;
            text-align: center;

            @media (max-width: $lg-down) {
                p.color-white {
                    color: $color-primary !important;
                }
            }

            &.two {
                font-size: 0.9rem;
                left: 19%;
                top: 33%;
                line-height: 1rem;
                width: 96px;
                height: 68px;

                @media (max-width: 1024px) {
                    left: 18%;
                }

                @media (max-width: $lg-down) {
                    width: 135px;
                    height: 135px;
                    border: 10px solid #ca138c;
                    background: $color-white;
                    border-radius: 50%;
                    top: 0;
                    left: 5%;
                }

                @media (max-width: $md-down) {
                    top: 50px;
                    left: 5%;
                    width: 85px;
                    height: 85px;
                    background: $color-white;
                    border: 5px solid #ca138c;
                    border-radius: 50%;
                }

                .number {
                    font-size: 2.5rem;

                    @media (max-width: $md-down) {
                        font-size: 2.8rem;
                        line-height: 3rem;
                    }
                }

                .spin1 {
                    width: 168%;
                    top: -78%;
                    left: -36%;
                    animation: spin 10s linear infinite;
                }

                .spin2 {
                    width: 123%;
                    top: -47%;
                    left: -13%;
                    animation: spin 12s linear infinite;
                    animation-direction: reverse;
                }

                a.btn-timeline {
                    bottom: -25px;
                    right: -10px;
                }

                .elem1 {
                    top: -70px;
                    left: -20px;
                    width: 15%;
                }

                .elem2 {
                    top: -40px;
                    left: 120px;
                }
            }

            &.three {
                left: 44%;
                top: 7%;
                width: 135px;
                height: 135px;
                border: 10px solid $color-primary;
                border-radius: 50%;
                line-height: 0.9rem;
                font-size: 0.9rem;

                @media (max-width: 1024px) {
                    left: 43%;
                    top: 0%;
                }

                @media (max-width: $lg-down) {
                    background: $color-white;
                    top: 0;
                    left: 40%;
                }

                @media (max-width: $md-down) {
                    top: 50px;
                    left: 37%;
                    width: 85px;
                    height: 85px;
                    background: $color-white;
                    border: 5px solid #ca138c;
                    border-radius: 50%;
                }

                .number {
                    font-size: 2.5rem;
                    line-height: 1.5rem;

                    @media (max-width: $md-down) {
                        font-size: 2.5rem;
                        line-height: 1.5rem;
                    }
                }

                .spin1 {
                    width: 225px;
                    top: -47%;
                    left: -47%;
                    animation: spin 6s linear infinite;
                    animation-direction: reverse;
                }

                .spin2 {
                    width: 140%;
                    top: -20%;
                    left: -20%;
                    animation: spin 7s linear infinite;
                }

                a.btn-timeline {
                    bottom: -8px;
                    right: -10px;
                }

                .elem1 {
                    top: -60px;
                    left: -20px;
                }
            }

            &.four {
                font-size: 0.9rem;
                left: 71%;
                top: 24%;
                line-height: 1rem;
                width: 95px;
                height: 95px;

                @media (max-width: 1024px) {
                    left: 70.2%;
                    top: 20%;
                }

                @media (max-width: $lg-down) {
                    width: 135px;
                    height: 135px;
                    border: 10px solid #ca138c;
                    background: $color-white;
                    border-radius: 50%;
                    top: 0;
                    left: unset;
                    right: 5%;
                }

                @media (max-width: $md-down) {
                    top: 50px;
                    width: 85px;
                    height: 85px;
                    background: $color-white;
                    border: 5px solid #ca138c;
                    border-radius: 50%;
                }

                .number {
                    font-size: 3rem;

                    @media (max-width: 1024px) {
                        font-size: 2.5rem;
                    }

                    @media (max-width: $lg-down) {
                        font-size: 2.5rem;
                        line-height: 2.5rem;
                    }

                    @media (max-width: $md-down) {
                        font-size: 2.8rem;
                        line-height: 2.8rem;
                    }
                }

                a.btn-timeline {
                    bottom: -23px;
                    right: -10px;
                }

                .elem1 {
                    top: -70px;
                    left: 40px;
                }

                .elem2 {
                    top: -50px;
                    left: 90px;
                }
            }
        }
    }

    .timeline-number.rehabiliter {
        .round {
            position: absolute;
            text-align: center;

            &.one {
                font-size: 0.9rem;
                left: 9.8%;
                top: 28.6%;
                width: 106px;
                height: 100px;

                .number {
                    line-height: 3rem;
                    font-size: 2.4rem;
                }

                @media (max-width: 1024px) {
                    left: 9%;

                    .number {
                        font-size: 2.5rem;
                    }
                }

                @media (max-width: $lg-down) {
                    left: 0;
                    top: 60px;
                    background: $color-primary;
                    border-radius: 50%;
                    width: 115px;
                    height: 115px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }

                @media (max-width: $md-down) {
                    top: 30px;
                }

                .spin1 {
                    width: 185px;
                    top: -47%;
                    left: -39%;
                    animation: spin 12s linear infinite;
                }

                .spin2 {
                    width: 140px;
                    top: -24%;
                    left: -17%;
                    animation: spin 6s linear infinite;
                    animation-direction: reverse;
                }

                a.btn-timeline {
                    bottom: -25px;
                    right: -20px;
                }

                .elem1 {
                    top: -80px;
                    left: 10px;
                    width: 20%;
                }

                .elem2 {
                    top: -80px;
                    left: 90px;
                    width: 10%;
                }
            }

            &.two {
                left: 33%;
                top: 29%;
                width: 105px;
                height: 105px;
                line-height: 0.8rem;
                font-size: 0.8rem;

                .number {
                    line-height: 2.3rem;
                    font-size: 2.5rem;
                }

                @media (max-width: 1024px) {
                    left: 32%;
                    top: 25%;

                    .number {
                        font-size: 2.2rem;
                    }
                }

                @media (max-width: $lg-down) {
                    left: 28%;
                    top: 60px;
                    width: 115px;
                    height: 115px;
                    border: 10px solid $color-primary;
                    background-color: $color-white;
                    border-radius: 50%;
                }

                @media (max-width: $md-down) {
                    top: 30px;
                    left: unset;
                    right: 0;
                }

                a.btn-timeline {
                    bottom: -20px;
                    right: -10px;
                }

                .spin1 {
                    width: 230px;
                    top: -63%;
                    left: -60%;
                    animation: spin 10s linear infinite;
                }

                .spin2 {
                    width: 154%;
                    top: -30%;
                    left: -26%;
                    animation: spin 9s linear infinite;
                    animation-direction: reverse;
                }

                .elem1 {
                    top: -70px;
                    left: -30px;
                }
            }

            &.three {
                font-size: 0.9rem;
                left: 58.5%;
                top: 32%;
                line-height: 0.9rem;
                width: 120px;

                .number {
                    line-height: 3.3rem;
                    font-size: 5.6rem;
                }

                @media (max-width: 1024px) {
                    left: 57.5%;
                    top: 28%;

                    .number {
                        font-size: 2.5rem;
                    }
                }

                @media (max-width: $lg-down) {
                    left: 56%;
                    top: 60px;
                    width: 115px;
                    height: 115px;
                    border: 10px solid $color-primary;
                    border-radius: 50%;
                    background-color: $color-white;
                }

                @media (max-width: $md-down) {
                    top: 160px;
                    left: 0;
                }

                a.btn-timeline {
                    bottom: -35px;
                    right: -20px;
                }
            }

            &.four {
                left: 83%;
                top: 38%;
                line-height: .9rem;
                font-size: .9rem;
                width: 88px;

                .number {
                    font-size: 1.75rem;
                    line-height: 1.5rem;
                }

                @media (max-width: 1024px) {
                    left: 84.2%;
                    top: 40%;
                    line-height: .6rem;

                    .number {
                        font-size: 1.4rem;
                    }
                }

                @media (max-width: $lg-down) {
                    left: unset;
                    right: 0;
                    top: 60px;
                    border: 10px solid $color-primary;
                    background-color: $color-white;
                    border-radius: 50%;
                    width: 115px;
                    height: 115px;
                    font-size: 1rem;
                    line-height: 1rem;
                }

                @media (max-width: $md-down) {
                    top: 160px;
                }

                a.btn-timeline {
                    bottom: -35px;
                    right: -20px;
                }

                .spin1 {
                    width: 220px;
                    top: -105%;
                    left: -77%;
                    animation: spin 13s linear infinite;
                }

                .elem1 {
                    top: -105px;
                    width: 15%;
                }

                .elem2 {
                    top: -115px;
                    left: 40px;
                    width: 25%;
                }

                .elem3 {
                    top: -90px;
                    left: 90px;
                    width: 25%;
                    animation: spin 9s ease infinite;
                }

                .elem4 {
                    top: -80px;
                    left: 110px;
                    width: 25%;
                }
            }
        }
    }

    .timeline-number.rse {
        .round {
            position: absolute;
            text-align: center;

            &.one {
                font-size: 0.9rem;
                left: 9.8%;
                top: 28.6%;
                width: 106px;
                height: 100px;

                .number {
                    line-height: 3rem;
                    font-size: 3rem;
                }

                @media (max-width: 1024px) {
                    left: 9%;

                    .number {
                        font-size: 2.5rem;
                    }
                }

                @media (max-width: $lg-down) {
                    left: 0;
                    top: 60px;
                    background: $color-primary;
                    border-radius: 50%;
                    width: 115px;
                    height: 115px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }

                @media (max-width: $md-down) {
                    top: 30px;
                    width: 135px;
                    height: 135px;
                }

                .spin1 {
                    width: 185px;
                    top: -47%;
                    left: -39%;
                    animation: spin 12s linear infinite;
                }

                .spin2 {
                    width: 140px;
                    top: -24%;
                    left: -17%;
                    animation: spin 6s linear infinite;
                    animation-direction: reverse;
                }

                a.btn-timeline {
                    bottom: -25px;
                    right: -20px;
                }

                .elem1 {
                    top: -80px;
                    left: 10px;
                    width: 20%;
                }

                .elem2 {
                    top: -80px;
                    left: 90px;
                    width: 10%;
                }
            }

            &.two {
                left: 33%;
                top: 29%;
                width: 105px;
                height: 105px;
                line-height: 0.8rem;
                font-size: 0.8rem;

                .number {
                    line-height: 2.3rem;
                    font-size: 2.5rem;
                }

                @media (max-width: 1024px) {
                    left: 32%;
                    top: 25%;

                    .number {
                        font-size: 2.2rem;
                    }
                }

                @media (max-width: $lg-down) {
                    left: 28%;
                    top: 60px;
                    width: 115px;
                    height: 115px;
                    border: 10px solid $color-primary;
                    background-color: $color-white;
                    border-radius: 50%;
                }

                @media (max-width: $md-down) {
                    top: 30px;
                    left: unset;
                    right: 0;
                    width: 135px;
                    height: 135px;
                }

                a.btn-timeline {
                    bottom: -20px;
                    right: -10px;
                }

                .spin1 {
                    width: 230px;
                    top: -63%;
                    left: -60%;
                    animation: spin 10s linear infinite;
                }

                .spin2 {
                    width: 154%;
                    top: -30%;
                    left: -26%;
                    animation: spin 9s linear infinite;
                    animation-direction: reverse;
                }

                .elem1 {
                    top: -70px;
                    left: -30px;
                }
            }

            &.three {
                font-size: 0.9rem;
                left: 58.5%;
                top: 25%;
                line-height: 0.9rem;
                width: 120px;

                .number {
                    line-height: 3.3rem;
                    font-size: 3.6rem;
                }

                @media (max-width: 1024px) {
                    left: 57.5%;
                    top: 28%;

                    .number {
                        font-size: 2.5rem;
                    }
                }

                @media (max-width: $lg-down) {
                    left: 56%;
                    top: 60px;
                    width: 115px;
                    height: 115px;
                    border: 10px solid $color-primary;
                    border-radius: 50%;
                    background-color: $color-white;
                }

                @media (max-width: $md-down) {
                    top: 160px;
                    left: 0;
                    width: 135px;
                    height: 135px;
                }

                a.btn-timeline {
                    bottom: -35px;
                    right: -20px;
                }
            }

            &.four {
                left: 83%;
                top: 42%;
                line-height: .9rem;
                font-size: .9rem;
                width: 88px;

                .number {
                    font-size: 1.75rem;
                    line-height: 1.5rem;
                }

                @media (max-width: 1024px) {
                    left: 84.2%;
                    top: 40%;
                    line-height: .6rem;

                    .number {
                        font-size: 1.4rem;
                    }
                }

                @media (max-width: $lg-down) {
                    left: unset;
                    right: 0;
                    top: 60px;
                    border: 10px solid $color-primary;
                    background-color: $color-white;
                    border-radius: 50%;
                    width: 115px;
                    height: 115px;
                    font-size: 1rem;
                    line-height: 1rem;
                }

                @media (max-width: $md-down) {
                    top: 160px;
                    width: 135px;
                    height: 135px;
                }

                a.btn-timeline {
                    bottom: -35px;
                    right: -20px;
                }

                .spin1 {
                    width: 220px;
                    top: -153%;
                    left: -77%;
                    animation: spin 13s linear infinite;
                }

                .elem1 {
                    top: -105px;
                    width: 15%;
                }

                .elem2 {
                    top: -115px;
                    left: 40px;
                    width: 25%;
                }

                .elem3 {
                    top: -90px;
                    left: 90px;
                    width: 25%;
                    animation: spin 9s ease infinite;
                }

                .elem4 {
                    top: -80px;
                    left: 110px;
                    width: 25%;
                }
            }
        }
    }
}
