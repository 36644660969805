#projeter {
    h2 {
        // font-size: 6rem;
        // line-height: 6rem;

        .feuille {
            display: inline-block;
            position: relative;
            &::before {
                position: absolute;
                z-index: 1;
                content: ' ';
                width: 50%;
                height: 50%;
                top: 25%;
                left: 25%;
                background: $color-white;
                animation: opacity 3s ease infinite;
            }
            img {
                position: relative;
                z-index: 1;
            }
        }
    }

    small {
        font-size: 1.10rem;
    }

    .text-neg {
        position: relative;
        margin-top: -110px;
        width: 55%;

        @media (max-width: $lg-down) {
            margin-top: 0px;
            width: auto;
        }
    }

    /*.coeur {
        position: absolute;
        bottom: -30%;
        right: 1%;
        z-index: 1;
        transform-origin: bottom left;
        transform: rotate(-3deg);
        animation: spinheartright 3s ease infinite;

        &.left {
            transform-origin: bottom right;
            transform: rotate(3deg);
            right: 83px;
            animation: spinheartleft 3s ease infinite;
        }
    }*/

    .fusee {
        position: absolute;
        z-index: 1;
        top: 150px;
        left: 200px;
        opacity: 0;
        animation: fusee 4s ease-in infinite;
    }

    .banner {
        background: url('#{$path-img}/projeter/projeter-bandeau.jpg') no-repeat center -360px;
        background-size: 104% auto;
        min-height: 460px;
        border-top: 40px solid $color-primary;

        @media (max-width: $md-down) {
            background-position: center center;
            background-size: cover;
            min-height: 350px;
        }
    }

    .afaq {
        position: relative;

        &::before {
            position: absolute;
            content: ' ';
            display: block;
            top: -5px;
            left: 0px;
            height: 100%;
            width: 100%;
            border: 15px solid $color-secondary;
            transform: rotate(-1deg);
        }
    }

    .qlq-chiffres {
        position: relative;

        >div {
            position: relative;
            z-index: 5;
        }

        &::before {
            position: absolute;
            content: ' ';
            display: block;
            top: -4px;
            left: 15px;
            height: 103%;
            width: 99%;
            z-index: -1;
            background: $color-secondary;
            transform: rotate(2deg);
            //animation: rotatelight 5s ease infinite;
            @media (max-width: $md-down) {
                display: none;
            }
        }
    }

    .chiffre-cles {
        @extend .px-3, .py-3, .my-3;

        position: relative;
        color: $white;

        &::before {
            content: '';
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            z-index: -1;
            display: block;
            background: $color-primary;
            transform: rotate(-2deg);
        }
    }

    .rse-steps {
        .step {
            h3 {
                @extend .d-flex;
                @extend .align-items-center;

                &::before {
                    display: inline-block;
                    content: '1';
                    width: 100px;
                    height: 100px;
                    font-family: $font-intrometro;
                    color: $color-primary;
                    border: 10px solid $color-primary;
                    border-radius: 50%;
                    flex: 0 0 auto;
                    text-align: center;
                    font-size: 4rem;
                    margin-right: 1rem;

                    @media (max-width: $lg-down) {
                        width: 55px;
                        height: 55px;
                        font-size: 3rem;
                        border: 7px solid $color-primary;
                    }
                }

                &.two {
                    &::before {
                        content: '2';
                    }
                }
                &.three {
                    &::before {
                        content: '3';
                    }
                }
                &.four {
                    &::before {
                        content: '4';
                    }
                }
                &.five {
                    &::before {
                        content: '5';
                    }
                }
                &.six {
                    &::before {
                        content: '6';
                    }
                }
                &.seven {
                    &::before {
                        content: '7';
                    }
                }
            }
        }
    }

    .strat {
        >div {
            position: relative;

            &::before {
                position: absolute;
                content: ' ';
                display: block;
                top: -18px;
                left: -24px;
                height: 103%;
                width: 108%;
                border: 15px solid $color-primary;
                transform: rotate(-1deg);
                transition: $default-transition;
            }

            &:hover {
                &::before {
                    transform: rotate(1deg);
                }
            }
        }
    }

}
